import './css/section_01.css'
function Choice(){
    return (
     <>
     <seciton class="p_l_d_b"  style={{zIndex:'3'}}>
         <div class="careers_visual_04">
          <img src="https://yogibo.kr/yogico/img/banner/yogico_careers_w_04.jpg" style={{width:'100%'}} className="careers_visual_04_img"/>
          <img src="https://yogibo.kr/yogico/img/banner/m_main_04.jpg" style={{width:'100%'}} className="careers_visual_01_m_img"/>    
         </div>
        <div className="section_01_inner">
            <div class="choice_iframe">
            <iframe src="https://leshwan.cafe24.com/yogico/form.php"></iframe>
            </div>
        </div>
      </seciton>
     </>
    )
  }

  
  export default Choice
  