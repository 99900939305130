function Section_02(){
   return(
      <>
      <section class="p_l_d_b2">
      <div class="boarder_wrapper" data-aos="fade-up" data-aos-duration="800">
         <div class="news_title">NEWS</div>
         <div class="news_title_more"  onClick={() => window.open('https://yogico.kr/news', '_blank')}>
         더보기 &nbsp;<img src="http://yogibo.kr//web/img/icon/new/arrow_right.svg" alt=""></img>
         </div>
         
         <div class="row">
        

         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/뷰티트래블.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt>요기보, 2024 서울뷰티트래블위크</dt>
                           <dd>협찬사로 참여</dd>
                        </dl>		
                     </div>
                     <div class="board_cont">
                        글로벌 라이프스타일 브랜드 요기보(Yogibo)가 2024 서울뷰티트래블위크의 협찬사로 참여한다. 
                        요기보는 기업 명상 교육 전문 마음챙김 명상 플랫폼인 하루명상과의 협업을 통해, 요기보의 시그니처 
                        빈백 소파를 활용한 특별 웰니스 프로그램을 전 세계 최초로 선보일 예정이다.
                        서울시 주관으로 진행되는 2024 서울뷰티트래블위크는 올해로 3회째를 맞이하는 K-웰니스 행사로 서울숲 공원 내 
                        여러 공간에서 요가, 음악, 싱잉볼, 춤 등을 활용한 마음챙김 명상 프로그램을 통해 요기보 제품을 직접 체험할 수 있게 된다.
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('http://www.kdpress.co.kr/news/articleView.html?idxno=132087 ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>
         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/뷰티트래블2.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt> Discover the Unique Beauty of Seoul at 2024 Beauty Travel Week</dt>
                        </dl>		
                     </div>
                     <div class="board_cont">               
                        - Event to take place across Seoul Forest Park and Seongdong-gu from Monday to Sunday, September 23 to 29.

                        - Boasts a wide variety of things to enjoy, taste, and do across Seongdong-gu, centered on the three topics of revival, refreshment, and rejuvenation.

                        - Over 50 local institutions and establishments to participate, including public parks, museums and galleries, eateries, and workshops.

                        - Seoul hopes to acquaint tourists from across Korea and around the world with “the unique beauty of Seoul.”
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('https://www.businesswire.com/news/home/20240919559349/en/Discover-the-Unique-Beauty-of-Seoul-at-2024-Seoul-Beauty-Travel-Week ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>

         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/이미지4.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt>전통과 현대의 조화: 한옥과 </dt>
                           <dd>Yogibo의 새로운 만남 </dd>
                        </dl>		
                     </div>
                     <div class="board_cont">
                     어쩌면 우리에게 익숙하기에 다소 정적이고 고루하게도 느껴질 수 있지만, 
                     한옥은 그 어느 건축 양식보다도 고즈넉한 편안함과 고요한 평온을 찾을 수 있는 곳이도 합니다.
                      '한국의 미를 상징하는 공간에 미국적인 컬러의 Yogibo가 과연 어울릴 수 있을까?'라는 질문을 오랫동안 해왔고 이에 대한 답을 찾기 위해 충남 태안 해변가에 위치한 한옥 한곳을 찾았습니다.
                      서울에서 약 2시간 반 정도 쉬지 않고 태안 방향으로 달리다 보면 서해안의 지평선이 서서히 보이기 시작할 즘, 늠름한 기와지붕과 단단한 목조 구조의 한옥이 하나둘씩 눈에 들어오기 시작합니다. Tiann (탼). 조
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('https://blog.naver.com/yogibo/223585725370 ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>         
       
                
        
         </div>
      </div>
      </section>
        
      </>
   )
  }
  export default Section_02