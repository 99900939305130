
import Section_01 from './Section_01.js'
function Corporation(){
    return(
        <>
         <Section_01/>
        </>
    )
}

export default Corporation